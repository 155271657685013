$micro: 320px;
$small: 480px;
$medium: 667px;
$vtab: 768px;
$htab: 1024px;
$large: 1366px;

header
{
    background: linear-gradient(to bottom right, rgb(255 255 255), rgba(99, 189, 127)), center center no-repeat;
    background-size: cover;
    display: flex;

    @media screen and (max-width: $medium)
    {
        height: auto;
    }

    >.row
    {
        width: 100%;
        .column
        {
            display: flex;

            &:nth-child(1),
            &:nth-child(2)
            {
                align-items: center;

                @media screen and (max-width: $medium)
                {
                    text-align: center;
                }
            }

            &:nth-child(3),
            &:nth-child(4)
            {
                align-items: flex-start;
            }
        }
    }
}

.logo
{
    width: 97px;
    margin-left: 7px;

    @media screen and (max-width: $medium)
    {
        position: relative;
        height: auto;
        margin: 0 auto;
    }

    @media screen and (min-width: $vtab) and (max-width: $htab)
    {
        height: auto;
        width: 70px;
    }
}

.text-decoration-none
{
    text-decoration: none;
}

.headerH1
{
    font-size: 28px;
    color: #00988d;
    font-weight: 700;
    margin-bottom: 6px;
    margin-left: 12px;

    @media screen and (max-width: $medium)
    {
        margin: 0 auto;
    }
}

.admin-headerH1
{
    font-size: 1.60rem;
    color: #00988d;
    font-weight: 700;
    margin-top: 12px;

    a
    {
        color: inherit;
        text-decoration: none;
    }
}

.navigation-bar
{
    position: relative;
    background-color: "#ffffff";
    padding: 2px;
    min-height: "74px";
    box-shadow: "rgba(0, 0, 0, 0.15) 2px 5px 1rem 4px";
    font-weight: 700;
    font-size: 1rem;
}

a.menu-link
{
    border: 2px solid transparent;
    border-radius: 18px;
    font-weight: 700 !important;
    margin: 6px 0;
}

#navbarScroll 
{
    a
    {
        font-weight: 600;
        color: black;

        &:hover 
        {
            color: #000000 !important;
            background-color: #ffffff !important;
        }
    }
    
}

.activeLink 
{
  border: 2px solid #01559e !important;
  color: #000000 !important;
  border-radius: 47px;

    &:active 
    {
        border: 2px solid #01559e;
    }
}

.linksPadding 
{
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 28px;
}

span.phone,
span.email
{   
    display: flex;
    margin-top: 34px;
    font-weight: 700;
    font-size: 16px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $medium)
    {
        font-size: 14px;
    }

    svg
    {
        padding: 1px 2px;
        border: 1px solid #000000;
    }
}