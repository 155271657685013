$micro: 320px;
$small: 480px;
$medium: 667px;

.sm-flex-center
{
    @media screen and (max-width: $small)
    {
        display: flex !important;
        justify-content: center;
    }
}

footer
{
    background-color: #22262f;
    color: #ffffff;
    padding: 10px;

    .container 
    {
        padding: 0;
    }
    
    .small-devices-text
    {
        display: inline;
        text-align: left;

        @media screen and (min-width: $micro) and (max-width: $medium) //320-667
        {
            text-align: center;
        }
    }
}

.footer-logo
{
    position: relative;

    img
    {   
        display: block;
        width: 100%;

        @media screen and (min-width: $medium)
        {
            width: 157px;
        }
    }
}

.footer-list
{
    padding: 0;

    @media screen and (min-width: $micro) and (max-width: $medium)
    {
        margin-bottom: 45px;
    }

    li
    {
        display: block;
        margin-bottom: 14px;

        @media screen and (max-width: $medium)
        {
            text-align: center;
        }

        a
        {
            text-decoration: none;

            &:hover
            {
                text-decoration: underline;
            }
        }

        p
        {
            display: inline;
        }

        svg
        {
            margin-right: 15px;
            display: inline;
        }
    }
}

.footer-info
{
    @media screen and (min-width: $small) //480
    {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    @media screen and (max-width: $small) //480
    {
        padding-right: 0;
        padding-left: 0;
    }

    h4
    {
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 28px !important;
    }
}

table.bank-details 
{
    text-align: left;
    
    td
    {
        &:nth-child(even)
        {
            padding-left: 10px;
        }
    }
}

section.copyright 
{
    border-top: 1px solid rgba(0,0,0,.1);
    padding: 18px 14px 0;

    @media screen and (max-width: $small) //480
    {
        border-top: 1px solid transparent;
        padding: 5px 14px 0;
    }

    p
    {
        display: block;
        border-top: 1px solid transparent;
        margin-bottom: 7px !important;
        width: 100%;

        @media screen and (max-width: $small) //480
        {
            border-top: 1px solid rgba(0,0,0,.1);
            padding: 18px 14px 0;
        }
    }

    a.powered-by
    {
        color: #007bff;
        font-weight: 700;
        text-decoration: none;
        background-color: transparent;

        &:hover
        {
            text-decoration: underline;
        }
    }
}